export const CHATROOM_SELECTOR_TYPES = [
    {
        id: Symbol.for("all"),
        label: "전체",
    },
    {
        id: Symbol.for("selling"),
        label: "판매",
    },
    {
        id: Symbol.for("buying"),
        label: "구매",
    },
];
